import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container } from "react-bootstrap"
import ResourcesIntroduction from "../../components/resourcesIntroduction"
import MarkdownContent from "../../components/markdownContent"
import Author from "../../components/author"
import RelatedResources from "../../components/relatedResources"
import Cta from "../../components/cta"
import Favicon from "../../images/favicon.png"

const Blog = ({ data, ...props }) => {
  const blog = data.strapiBlogs
  const allBlogs = data.allStrapiBlogs.nodes
  const relatedBlogs = allBlogs
    .filter(relatedBlog => {
      return relatedBlog.strapiId !== blog.strapiId
    })
    .slice(0, 3)
  return (
    <Layout>
      <Container className="page resources single blog">
        <Seo
          title={
            blog.seo !== null
              ? blog.seo.meta_title !== null
                ? blog.seo.meta_title
                : "Geen meta titel"
              : ""
          }
          image={{
            src:
              blog.seo !== null
                ? blog.seo.meta_image !== null
                  ? blog.seo.meta_image.image.localFile.publicURL
                  : ""
                : "",
            height: 628,
            width: 1200,
          }}
          description={
            blog.seo !== null
              ? blog.seo.meta_description !== null
                ? blog.seo.meta_description
                : ""
              : ""
          }
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <ResourcesIntroduction
          title={blog.title}
          subtitle="Blogs"
          classes="single-resource_intro"
          padding={{ bottom: 0, top: 80, left: 15, right: 15 }}
          resource={blog}
          name="Blogs"
        />
        <MarkdownContent content={blog.content} props={props} />
        <Author
          name={blog.author.full_name}
          description={blog.author.about}
          link={blog.author.email}
          image={blog.author.profile_picture}
        />
        <RelatedResources
          title="Gerelateerde artikelen"
          data={relatedBlogs}
          name="Blogs"
          padding={{ bottom: 0, top: 160, left: 15, right: 15 }}
        />
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    strapiBlogs(slug: { eq: $slug }) {
      strapiId
      content
      time_to_read
      title
      updated_at
      slug
      featured_image {
        alternativeText
        localFile {
          absolutePath
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: WEBP
              width: 1000
              aspectRatio: 1.5
            )
          }
        }
        updated_at
      }
      created_at
      author {
        full_name
        email
        about
        position
        id
        profile_picture {
          alternativeText
          localFile {
            absolutePath
            publicURL
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: WEBP
                width: 200
                aspectRatio: 1.5
              )
            }
          }
        }
      }
      blog_categories {
        name
        slug
        id
      }
      labels {
        id
        slug
        name
        color
      }
      seo {
        id
        indexable
        meta_description
        meta_keywords
        meta_title
        meta_image {
          meta_image_alt
          image {
            localFile {
              absolutePath
              publicURL
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
        }
      }
    }
    allStrapiBlogs(sort: { fields: published_at, order: DESC }) {
      nodes {
        id
        strapiId
        title
        slug
        time_to_read
        published_at(formatString: "DD MMMM YYYY", locale: "nl")
        updated_at(formatString: "DD MMMM YYYY", locale: "nl")
        labels {
          id
          name
          slug
        }
        blog_categories {
          id
          name
          slug
        }
        featured_image {
          alternativeText
          url
          localFile {
            absolutePath
            publicURL
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5)
            }
          }
        }
      }
    }
  }
`

export default Blog
